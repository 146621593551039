import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import SimpleCta from "../components/simple-cta";
import TwoColumnImageContent from "../components/two-column-image-content";

const CarbonOffesettingServices = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "carbon-offsetting-services" }) {
				carbonOffsettingServices {
					offsettingBannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					offsettingTwoColumnImageContent {
						twoColumnHeading
						twoColumnTagline
						twoColumnContent
						twoColumnCta1 {
							title
							url
							target
						}
						twoColumnCta2 {
							title
							target
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
						twoColumnImageBackgroundColor
					}
					offsettingTwoColumnBgImageSection {
						twoColumnHeading
						twoColumnContent
						twoColumnBackground
						twoColumnBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					offsettingSimpleTextButtonCta {
						simpleTextButtonCtaHeading
						simpleTextButtonCtaContent
						ctaButton1 {
							title
							url
							target
						}
						ctaButton2 {
							title
							target
							url
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, carbonOffsettingServices },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Carbon Credits",
				item: {
					url: `${siteUrl}/carbon-credits`,
					id: `${siteUrl}/carbon-credits`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/carbon-credits`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						carbonOffsettingServices?.offsettingBannerSection
							.bannerSectionHeading
					}
					tagline={
						carbonOffsettingServices?.offsettingBannerSection
							.bannerSectionSubHeading
					}
					content={
						carbonOffsettingServices?.offsettingBannerSection
							.bannerSectionContent
					}
					bgImage={
						carbonOffsettingServices?.offsettingBannerSection
							.bannerSectionBackground?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<TwoColumnImageContent
					heading={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnHeading
					}
					content={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnContent
					}
					tagline={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnTagline
					}
					btnCta1={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnCta1
					}
					btnCta2={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnCta2
					}
					image={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnImage?.node
					}
					bgColor={
						carbonOffsettingServices?.offsettingTwoColumnImageContent
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<TwoColumnImageContent
					heading={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnHeading
					}
					content={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnContent
					}
					tagline={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnTagline
					}
					image={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnImage.node
					}
					mainBgColor={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnBackground
					}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					bgImage={
						carbonOffsettingServices?.offsettingTwoColumnBgImageSection
							.twoColumnBackgroundImage.node.sourceUrl
					}
					flip={true}
				/>
				<SimpleCta
					heading={
						carbonOffsettingServices?.offsettingSimpleTextButtonCta
							.simpleTextButtonCtaHeading
					}
					content={
						carbonOffsettingServices?.offsettingSimpleTextButtonCta
							.simpleTextButtonCtaContent
					}
					btnCta1={
						carbonOffsettingServices?.offsettingSimpleTextButtonCta.ctaButton1
					}
					btnCta2={
						carbonOffsettingServices?.offsettingSimpleTextButtonCta.ctaButton2
					}
				/>
			</Layout>
		</>
	);
};

export default CarbonOffesettingServices;
